<template>
    <main class="bg-white">
        <div class="relative flex">
            <div class="w-full md:w-1/2">
                <div class="min-h-screen h-full flex flex-col">
                    <div class="um mb-8 mt-10">
                        <div class="flex justify-between align-items-center md:w-3/5 mx-auto h-16 px-5 md:px-6">
                            <a class="block" href="javascript:void(0);" style="outline: currentcolor none medium;">
                                <img class="h-10" src="./../../assets/jit-logo.svg" alt="">
                            </a>
                        <!-- <h1 class="text-2xl text-gray-800 font-bold mb-4">{{ $t('signIn') }}✨</h1> -->
                        </div>
                    </div>
                    <div class="w-full md:w-3/5 pt-10 py-3 px-4 mx-auto">
                        <!-- <h1 class="text-3xl text-gray-800 font-bold mb-4">Welcome back! ✨</h1> -->
                        <form @submit.prevent="loginUser">
                            <div class="fn">
                                <div>
                                    <label class="block text-sm font-medium text-theme-gray tracking-wide mb-3" for="email" style="outline: currentcolor none medium;">{{ $t('username') }}</label> 
                                    <input id="email" v-model="form.email" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-900 font-semibold text-sm" type="text" style="outline: currentcolor none medium;">
                                </div>
                                <div class="mt-3">
                                    <label class="block text-sm font-medium text-theme-gray tracking-wide mb-3" for="password" style="outline: currentcolor none medium;">{{ $t('password') }}</label> 
                                    <input id="password" v-model="form.password" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-900 font-semibold text-sm" type="password" autocomplete="on" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col space-y-5 mt-6">
                                <button :disabled='processing' class="w-full focus:outline-none bg-deep-blue font-black rounded text-white px-3 py-3" style="outline: currentcolor none medium;">
                                    <span class="flex justify-center" v-if="processing">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('signin') }}
                                    </span>
                                    <span v-else>{{ $t('signIn') }}</span>
                                </button>
                                <div class="flex justify-end">
                                    <router-link :to="{ name : 'forget' }" class="text-sm no-underline text-gray-600" style="outline: currentcolor none medium;">{{ $t('forgetPassword') }}?</router-link>
                                </div>
                            </div>
                        </form>
                        <div class="flex flex-col space-y-5 border-gray-200">
                            <div v-if="message.data" class="mt-10 mb-10 text-center px-3 py-1 rounded border" :class="message.error ? 'border-red-400' : message.success ? 'border-green-500' : ''">
                                <span :class="message.error ? 'text-red-400' : message.success ? 'text-green-500' : ''">{{ message.data }}</span>
                            </div>
                            <div class="text-center mt-10 text-sm text-gray-600">
                                {{ $t('notCustomer') }}? 
                                <router-link :to="{ name : 'signup' }" class="font-medium text-indigo-500 text-blue-400" style="outline: currentcolor none medium;">{{ $t('signUp')}}</router-link>
                            </div>
                            <!-- <div class="mt-5">
                                <div class="cv mu px pc rounded">
                                    <svg class="inline w-3 h-3 ug hk" viewBox="0 0 12 12"><path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z"></path>
                                    </svg> 
                                    <span class="text-sm">To support you during the pandemic super pro features are free until March 31st.</span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden md:block absolute md:w-1/2 image-container" aria-hidden="true">
                <img class="object-cover h-full max-w-full" src="./../../assets/images/auth-image.png" alt="Authentication image" width="760" height="1024"> 
                <!-- <img class="tp ns tb ar u_ iv hidden _d" src="images/auth-decoration.png" alt="Authentication decoration" width="218" height="224"> -->
            </div>
        </div>
    </main>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name : 'AuthLogin',
        data () {
            return {
                form : {
                    email : '',
                    password : ''
                },
                message : {
                    error : false,
                    success : false,
                    data : ''
                },
                processing : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
            })
        },
        mounted () {
            
        },
        beforeMount () {
            if (this.USER_TOKEN) {
                this.$router.replace({ name : 'dashboard' })
            }
        },
        methods : {
            loginUser () {
                if (this.form.email === '' || this.form.password === '') {
                    this.message.data = this.$t('fieldRequired')
                    this.message.error = true
                    this.message.success = false
                    // this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else {
                    this.processing = true
                    this.$store.dispatch('auth/loginUser', { username : this.form.email, password : this.form.password })
                    .then(_ => {
                        this.processing = false
                        this.message.data = this.$t('loginSuccessful')
                        this.message.success = true
                        this.message.error = false
                        // this.$services.helpers.notification(this.$t('loginSuccessful'), 'success', this)
                        setTimeout(() => {
                            this.$router.replace({ name : 'webshop' })
                        }, 2000)
                    })
                    .catch(error => {
                        // console.log(error.response)
                        this.processing = false
                        if (error.response.status === 400) {
                            this.message.data = this.$t('loginError')
                            this.message.success = false
                            this.message.error = true
                            // this.$services.helpers.notification(error.response.data.text[0], 'error', this)
                            // console.log(error.response)
                        }
                    })
                }
            },
        }
    }
</script>

<style  scoped>
.image-container {
    top: 0;
    right: 0;
    bottom: 0;
}
</style>