<template>
    <div class="bg-gray-100">
        <div class="flex justify-between align-items-center p-2">
            <button @click="clearDataField" class="bg-white text-xs rounded border shadow-sm p-1">{{ $t('clear') }}</button>
            <small class="text-xs">{{ $t('filter') }}</small>
            <button @click="filterDataNow" class="bg-blue-400 text-xs text-white rounded border shadow-sm p-1">{{ $t('done') }}</button>
        </div>
        <div class="flex flex-col">
            <div>
                <div class="bg-white border-t border-b">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="radio" class="form-radio cursor-pointer" v-model="filter" value="date"/>
                        <span class="ml-2 cursor-pointer text-xs">{{ $t('date') }}</span>
                    </label>
                </div>
                <div v-if="filter === 'date'" class="p-2">
                    <div class="form-group">
                        <select v-model="dateObject.range" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                            <option selected disabled>---</option>
                            <option value='equal'>{{ $t('isEqualTo') }}</option>
                            <option value='greater'>{{ $t('isGreaterThan') }}</option>
                            <option value='less'>{{ $t('isLessThan') }}</option>

                            <option value='between'>{{ $t('isBetween') }}</option>
                        </select>
                    </div>
                    <div class="block" v-if="dateObject.range === 'between'">
                        <input type="date" min="0" v-model="dateObject.start" class="bg-white w-1/2 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                        <input type="date" min="0" v-model="dateObject.end" class="bg-white w-1/2 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                    </div>
                    <div v-else>
                        <input type="date" min="0" v-model="dateObject.date" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                    </div>
                </div>
                <div class="bg-white border-b">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="radio" class="cursor-pointer" v-model="filter" value="amount"/>
                        <span class="ml-2 cursor-pointer text-xs">{{ $t('total') }}</span>
                    </label>
                </div>
                <div v-if="filter === 'amount'" class="p-2">
                    <div class="form-group">
                        <select v-model="amountObject.range" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                            <option selected disabled>---</option>
                            <option value='equal'>{{ $t('isEqualTo') }}</option>
                            <option value='greater'>{{ $t('isGreaterThan') }}</option>
                            <option value='between'>{{ $t('isBetween') }}</option>
                            <option value='less'>{{ $t('isLessThan') }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <div class="flex justify-between w-full" v-if="amountObject.range === 'between'">
                            <input type="number" min="0" v-model="amountObject.start" class="bg-white w-1/2 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                            <input type="number" min="0" v-model="amountObject.end" class="bg-white w-1/2 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                        </div>
                        <div v-else>
                            <input type="number" min="0" v-model="amountObject.amount" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                        </div>
                    </div>
                </div>
                <div class="bg-white border-b">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="radio" class="form-radio cursor-pointer" v-model="filter" value="status"/>
                        <span class="ml-2 cursor-pointer text-xs">{{ $t('status') }}</span>
                    </label>
                </div>
                <div v-if="filter === 'status'" class="p-2">
                    <div class="form-group">
                        <select v-model="statusObject.status" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                            <option selected disabled value="">---</option>
                            <option value='Completed'>{{ $t('orderCompleted') }}</option>
                            <option value='Processing'>{{ $t('orderProcessing') }}</option>
                            <option value='Cancelled'>{{ $t('orderCancel') }}</option>
                            <option value='Pending'>{{ $t('orderPending') }}</option>
                        </select>
                    </div>
                </div>
                <div class="bg-white border-b" v-if="data !== 'webshop'">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="radio" class="form-radio cursor-pointer" v-model="filter" value="orderNumber"/>
                        <span class="ml-2 cursor-pointer text-xs">{{ $t('orderNumber') }}</span>
                    </label>
                </div>
                <div v-if="filter === 'orderNumber'" class="p-2">
                    <div class="form-group">
                        <select v-model="orderNumber.status" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                            <option selected disabled value="">---</option>
                            <option value='asc'>{{ $t('ascending') }}</option>
                            <option value='desc'>{{ $t('descending') }}</option>
                        </select>
                    </div>
                </div>

                <div v-if="data === 'supplier' ">
                    <div class="bg-white border-b">
                        <label class="inline-flex align-items-center item-center mt-1 ml-3">
                            <input type="radio" class="form-radio cursor-pointer" v-model="filter" value="supplier"/>
                            <span class="ml-2 cursor-pointer text-xs">{{ $t('supplier') }}</span>
                        </label>
                    </div>
                    <div v-if="filter === 'supplier'" class="p-2">
                        <div class="form-group">
                            <input type="text" v-model="supplier.name" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'FilterComponent',
        props : {
            data : {
                type : String,
                required : true
            }
        },
        data () {
            return {
                filter : '',
                value1 : '',
                dateObject : {
                    range : '',
                    date : '',
                    start : '',
                    end : ''
                },
                amountObject : {
                    range : '',
                    amount : '',
                    start : '',
                    end : ''
                },
                statusObject : {
                    status : '',
                },
                orderNumber : {
                    status : ''
                },
                supplier : {
                    name : ''
                }
            }
        },
        watch : {
            filter : function (newVal, _) {
                this.filter = ''
                this.filter = newVal
            }
        },
        methods : {
            filterDataNow () {
                if (this.filter === 'date') {
                    // console.log('here', this.dateObject)
                    if (this.dateObject && this.dateObject.range) {
                        // if (this.data === 'webshop') {
                        //     // this.$store.commit('jitcontrol/ADVANCE_FILTER_WEBSHOP_DATE', this.dateObject)
                        //     this.$emit('filterDate', this.dateObject)
                        // }

                        // if (this.data === 'supplier') {
                        //     this.$store.commit('jitcontrol/ADVANCE_FILTER_SUPPLIER_DATE', this.dateObject)
                        // }
                        this.$emit('filterDate', this.dateObject)
                    }
                }

                if (this.filter === 'amount') {
                    if (this.amountObject.range && (this.amountObject.amount || this.amountObject.start || this.amountObject.end)) {
                        this.$emit('filterOrderAmount', this.amountObject)
                        
                        // if (this.data === 'webshop') {
                        //     this.$store.commit('jitcontrol/ADVANCE_FILTER_AMOUNT_WEBSHOP_ORDERS', this.amountObject)
                        // }

                        // if (this.data === 'supplier') {
                        //     this.$store.commit('jitcontrol/ADVANCE_FILTER_AMOUNT_SUPPLIER_ORDERS', this.amountObject)
                        // }
                    }
                }

                if (this.filter === 'status') {
                    if (this.statusObject && this.statusObject.status) {
                        // if (this.data === 'webshop') {
                        //     this.$store.commit('jitcontrol/ADVANCE_FILTER_WEBSHOP_STATUS', this.statusObject)
                        // }

                        // if (this.data === 'supplier') {
                        //     this.$store.commit('jitcontrol/ADVANCE_FILTER_SUPPLIER_STATUS', this.statusObject)
                        // }
                        this.$emit('filterStatus', this.statusObject)
                    }
                }

                if (this.filter === 'orderNumber') {
                    if (this.orderNumber && this.orderNumber.status) {
                        // if (this.data === 'webshop') {
                        //     this.$store.commit('jitcontrol/ADVANCE_SORT_WEBSHOP_ORDER_NUMBER', this.orderNumber)
                        // }

                        // if (this.data === 'supplier') {
                        //     this.$store.commit('jitcontrol/ADVANCE_SORT_SUPPLIER_ORDER_NUMBER', this.orderNumber)
                        // }
                        this.$emit('filterOrderNumber', this.orderNumber)
                    }
                }

                if (this.filter === 'supplier') {
                    if (this.supplier && this.supplier.name) {
                        // this.$store.commit('jitcontrol/ADVANCE_FILTER_SUPPLIER_TYPE', this.supplier)
                        this.$emit('filterSupplier', this.supplier)
                    }
                }

                // this.clearDataField()
                this.$emit('hideAdvanceFilter')
            },

            clearDataField () {
                this.filter = ''
                Object.keys(this.dateObject).forEach(element => { this.dateObject[element] = '' })
                Object.keys(this.amountObject).forEach(element => { this.amountObject[element] = '' })
                Object.keys(this.statusObject).forEach(element => { this.statusObject[element] = '' })
                Object.keys(this.orderNumber).forEach(element => { this.orderNumber[element] = '' })
                Object.keys(this.supplier).forEach(element => { this.supplier[element] = '' })
            }
        }
    }
</script>

<style lang="scss" scoped>
.el-date-editor--daterange.el-input__inner{
    width: unset !important;
}
</style>