<template>
    <!-- <div class="absolute bg-red-30 inset-0 z-10" @click="showActionBox = false" v-if="showActionBox"></div> -->
        <!-- <td>
            <span>
                <img class="h-28 w-32 object-contain" :src="supplier.company_logo" alt="supplier | jitcontrol" v-if="supplier.company_logo">
                <img class="h-28 w-32 object-contain" src="./../../assets/images/supplier.png" alt="supplier | jitcontrol" v-else>
            </span>
        </td> -->
        <td><span class="whitespace-nowrap p-2 md:p-0 text-sm text-gray-900">{{ $services.helpers.capitalizeNames(supplier.supplier.name) }}</span></td>
        <!-- <td><span class="whitespace-nowrap p-2 md:p-0 text-sm text-gray-900 text-xs">{{ $t('notAvailable') }}</span></td> -->
        <td><span class="flex items-center font-semibold space-x-4 whitespace-nowrap p-2 md:p-0 text-sm text-green-500">
            <span class="mr-2">{{ $t('notAvailable') }}</span>
        </span></td>
        <td>
            <div class="flex relative space-x-3 font-semibold whitespace-nowrap p-2 md:p-0 text-sm text-gray-400">
                <div class="absolute w-40 bg-white z-50 rounded border shadow top-rem" v-if="showActionBox">
                    <div>
                        <ul>
                            <li @click="emitUpdateEvent" class="hover:bg-deep-teal cursor-pointer hover:text-theme-blue">
                                <span class="flex items-center space-x-1 p-2">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span class="text-xs hover:text-theme-blue hover:font-black">{{ $t('update') }}</span>
                                </span>
                            </li>
                            <li class="hover:bg-deep-teal cursor-pointer hover:text-theme-blue">
                                <span class="flex items-center space-x-1 p-2">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span class="text-xs hover:text-theme-blue hover:font-black">{{ $t('disconnect') }}</span>
                                </span>
                            </li>
                            <li @click="emitDeleteEvent" class="hover:bg-deep-teal cursor-pointer hover:text-theme-blue">
                                <span class="flex items-center space-x-1 p-2">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                    </span>
                                    <span class="text-xs hover:text-theme-blue hover:font-black">{{ $t('delete') }}</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <span class="mr-2 cursor-pointer" @click="showActionBox = true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                    </svg>
                </span>
            </div>
        </td>
</template>

<script>
import Bus from '../../../bus'
    export default {
        name : 'SupplierOverviewTable',
        props : {
            supplier : {
                type : Object,
                require : true,
            }
        },
        emits : ['toggleEvent', 'emitDeleteEvent', 'emitDisconnectEvent', 'emitUpdateEvent'],
        data () {
            return {
                showActionBox : false
            }
        },
        watch : {
            showActionBox : function (n, o) {
                if (n) {
                    this.$emit('toggleEvent', true)
                }
            }
        },
        mounted () {
            Bus.on('removeActionBox', e => {
                this.showActionBox = false
            })
        },
        methods : {
            emitUpdateEvent () {
                this.$emit('emitUpdateEvent', this.supplier)
                this.showActionBox = false
            },
            emitDisconnectEvent () {
                this.$emit('emitDisconnectEvent', this.supplier)
            },
            emitDeleteEvent () {
                this.$emit('emitDeleteEvent', this.supplier)
                this.showActionBox = false
            }
        }
    }
</script>

<style lang="scss" scoped>
.table th {
    padding: .3rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.table tbody, td {
    padding: .7rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6 !important;
}
// .top-rem{
//     top: 27rem
// }
</style>