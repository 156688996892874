<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('priceRule') }}</div>
            <select v-model="selectedWebshop" @change="changeWebshop" class="px-5 py-1 rounded-sm focus:outline-none bg-white border text-xs text-gray-700">
                <option :value="webshop.uuid" v-for="webshop in GET_WEBSHOPS.results" :key="webshop.uuid">{{ webshop.shop_name }}</option>
            </select>
        </div>
        <div class="mt-10">
            <div class="border shadow-sm rounded-md shadow-sm overflow-hidden bg-light-teal">
                <form @submit.prevent="saveSettings" class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-7 gap-4 p-2 mb-20">
                    <div class="col-span-2 px-3">
                        <span class="text-gray-800 text-base font-semibold text-left">{{ $t('generalSetting') }}</span>
                    </div>
                    <div class="col-span-4">
                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2">
                            <input @change="onChangeMargin" v-model="marginInput" :placeholder="$t('setGlobalMargin')" type="text" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                            <select v-model="rounding" @change="onChangeRound" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                <option value="round" selected disabled>{{ $t('round') }}</option>
                                <option value="up">{{ $t('roundUp') }}</option>
                                <option value="down">{{ $t('roundDown') }}</option>
                            </select>
                            <select v-model="vatRate" @change="onChangeVat" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                <option value="vat" selected disabled>{{ $t('defaultVat') }}</option>
                                <option value="25">25%</option>
                                <option value="12">12%</option>
                                <option value="6">6%</option>
                            </select>
                            <select v-model="webshopPriceList" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                <option value='price' selected disabled>{{ $t('webshopPricelist') }}</option>
                                <option :value='priceList.webshop_price_list_id' v-for="priceList in GET_PRICE_LIST" :key="priceList.uuid">{{ priceList.webshop_price_list_id }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-span-1 flex">
                        <button :disabled="updating" class="focus:outline-none bg-theme-blue text-sm px-3 py-1 rounded-md shadow-md text-white">
                            <span v-if="updating">{{ $t('fetchingProduct') }}</span>
                            <span v-else>{{ $t('saveSetting') }}</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="absolute bg-red-30 inset-0 z-10" @click="showFilter = false" v-if="showFilter"></div>

        <div class="mt-10">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between md:space-x-8 items-center w-full bg-light-teal p-2 border-b">
                        <h2 class="text-sm font-semibold">{{ $t('product') }}</h2>
                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-1">
                            <div></div>
                            <div class="col-span-2 w-full flex md:space-x-1">
                                <button :disabled='calculating' @click="calculateSelectedItem" class="focus:outline-none w-1/2 whitespace-nowrap bg-theme-blue text-xs px-3 py-1 rounded-md shadow-md text-white">
                                    <span class="flex" v-if="calculating">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('calculating') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('calculateSelectItem') }}
                                    </span>
                                </button>
                                <button @click="applyAllCalculation" class="focus:outline-none w-3/4 whitespace-nowrap bg-theme-blue text-xs px-3 py-1 rounded-md shadow-md text-white">
                                    {{ $t('applyCalculateAll') }}
                                </button>
                            </div>
                            <div class="flex md:space-x-3 w-full">
                                <button :disabled="exporting" @click="exportFileExcel" class="focus:outline-none whitespace-nowrap bg-theme-yellow text-xs px-3 py-1 rounded-md shadow-md text-white">
                                    <span v-if="exporting">{{ $t('exporting') }}</span>
                                    <span v-else>{{ $t('exportFile') }}</span>
                                </button>
                                <button v-if="showUndoBtn" @click="undoSelection" class="focus:outline-none md:w-1/2 whitespace-nowrap bg-red-500 text-xs px-3 py-1 rounded-md shadow-md text-white">
                                    {{ $t('undoSelection') }}
                                </button>
                            </div>
                            <div class="flex align-items-center space-x-3">
                                <div class="w-1/2 relative">
                                    <button class="flex items-center md:space-x-2 text-xs bg-white rounded p-2 border focus:outline-none" @click="showFilter = !showFilter">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                                            </svg>
                                        </span>
                                        <span class="whitespace-nowrap">{{ $t('filter') }}</span>
                                    </button>
                                    <div class="absolute w-52 bg-white custom-z rounded border shadow mt-0 md:-ml-20" v-if="showFilter">
                                        <FilterComponent  @hideAdvanceFilter="showFilter = false"
                                            :pricelist="parseInt(priceListVal)" 
                                            :sub="selectedWebshop"
                                        />
                                    </div>
                                </div>
                                <input :placeholder="$t('search')" @input="searchInputTrigger" type="text" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                            </div>
                        </div>
                    </div>
                    <div class="tabledata flex flex-col w-full">
                        <div class="flex justify-center" v-if="loading">
                            <svg class="animate-spin mr-2 h-8 w-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                        <div class="block w-full overflow-x-auto md:overflow-x-hidden overflow-y-hidden mb-8">
                            <hot-table ref="wrapper"  :row-headers="true"  :settings="hotSettings" :style="style"> <!--:data="GET_WEBSHOP_PRODUCTS.data"-->
                                <hot-column data="product.sku" read-only="true">
                                </hot-column>
                                <hot-column data="product.vendor_number" read-only="true"> 
                                </hot-column>
                                <hot-column data="product.name" read-only="true" width="340"> <!--//-->
                                </hot-column>
                                <hot-column data="product.supplier.name" read-only="true">
                                </hot-column>
                                <hot-column data="supplier_product_price_stock.supplier_price" >
                                </hot-column>
                                <hot-column data="supplier_product_price_stock.user_set_supplier_price">
                                </hot-column>
                                <hot-column data="newSalesPrice">
                                </hot-column>
                                <hot-column data="webshop_discount_price" read-only="true">
                                </hot-column>
                                <hot-column data="margin">
                                </hot-column>
                            </hot-table>
                        </div>
                        <div class="flex justify-between p-2 text-xs">
                            <div class="flex">
                                <select @change="shopResultNumber" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                    <option selected disabled>{{ $t('show') }}</option>
                                    <option :value="i*100" v-for="i in 5" :key="i">{{ i*100 }}</option>
                                </select>
                            </div>
                            <div class="flex" v-if="GET_WEBSHOP_PRODUCTS">
                                <v-pagination
                                    v-model="GET_WEBSHOP_PRODUCTS.page_number"
                                    :pages="GET_WEBSHOP_PRODUCTS.total_pages"
                                    :range-size="1"
                                    active-color="#DCEDFF"
                                    @update:modelValue="changePageNumber"
                                />
                            </div>
                            <div class="flex md:space-x-2">
                                <button :disabled="updatingWebshop" @click="updateWebshop" class="focus:outline-none bg-theme-blue text-sm px-3 py-1 rounded-md shadow-md text-white">
                                    <span v-if="updatingWebshop" class="flex justify-between">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('updating') }}
                                    </span>
                                    <span v-else>{{ $t('updateWebshop') }}</span>
                                </button>
                                <button :disabled='savingPrice' @click="saveLocalDBPrice" class="focus:outline-none bg-theme-blue text-sm px-3 py-1 rounded-md shadow-md text-white">
                                    <span class="flex" v-if="savingPrice">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('updating') }}
                                    </span>
                                    <span v-else>
                                        <span class="font-semibold ml-2">{{ $t('save') }}</span>
                                    </span>
                                </button>
                            </div>
                            <!-- <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3">

                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import { HotTable, HotColumn } from '@handsontable/vue3'
import Handsontable from 'handsontable'
import FilterComponent from './minicomponent/FilterWebshopProduct'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import Bus from '../../bus'

export default {
    name : 'PriceRule',
    components : { HotTable, HotColumn, FilterComponent, VPagination },
    data () {
        return {
            selectedWebshop : null,
            processing : false,
            loading : true,
            showFilter : false,
            showActionBox : false,
            updating : false,
            exporting : false,
            showUndoBtn : false,
            calculating : false,
            updatingWebshop : false,
            priceListVal : [],
            selectedRow : [],
            storageData :  [],
            savingPrice : false,
            limit : 100,
            search : null,
            webshopPriceList : 'price',
            marginInput : '',
            vatRate : '25',
            rounding : 'round',
            style: 'height: 100vh; overflow: hidden; width: 100% !important; border: 1px solid red;',
            hotSettings : {
                data : [],
                licenseKey: 'non-commercial-and-evaluation',
                width: '100%',
                stretchH: 'all',
                selectionMode: 'multiple',
                outsideClickDeselects: false,
                beforeChange: this.beforeChangeHook,
                colHeaders: ['SKU', this.$t('vendorNumber'), this.$t('name'), this.$t('supplier'), this.$t('costPrice'), this.$t('salesPriceInkVat'), this.$t('salesPriceExVat'), this.$t('discountedPrice'), this.$t('margin')],
                columns: [
                    { data: 'product.sku' },
                    { data: 'product.vendor_number' },
                    { data: 'product.name' },
                    { data: 'product.supplier.name' },
                    { data: 'supplier_product_price_stock.supplier_price', type: 'numeric' },
                    { data: 'supplier_product_price_stock.user_set_supplier_price', type: 'numeric' },
                    { data: 'newSalesPrice', type: 'numeric' },
                    { data: 'webshop_discount_price', type: 'numeric' },
                    { data: 'margin', type: 'numeric' },
                ],
                // afterLoadData : function (sourceD, init, source) {

                // },
                cells : function (row, col) {
                        var cellProperties = {}
                        cellProperties.renderer = 'applyMargin' // uses lookup map
                        return cellProperties
                },
                afterSelectionEndByProp  : this.afterSelection,
                // afterUpdateSettings : function (newSe) {
                // }
            },
            hotRef: null,
        }
    },
    watch : {
        priceListVal : function (n, o) {
            if (n) {
                this.webshopPriceList = this.priceListVal
            }
        },
        showFilter  : function (newVl, _) {
            if (newVl === false) {
                this.hotRef.loadData(this.GET_WEBSHOP_PRODUCTS.results, 'edit') 
            }
        },
    },
    computed : {
        ...mapGetters({
            USER_REFRESH : 'auth/USER_REFRESH',
            GET_WEBSHOPS : 'jitcontrol/GET_WEBSHOPS',
            GET_PRICE_LIST : 'jitcontrol/GET_PRICE_LIST',
            GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
            GET_WEBSHOP_PRODUCTS : 'jitcontrol/GET_WEBSHOP_PRODUCTS',
            GET_SUPPLIERS : 'jitcontrol/GET_SUPPLIERS',
            GET_PRICE_RULE : 'jitcontrol/GET_PRICE_RULE',
            GET_CHOOSEN_COMPANY : 'jitcontrol/GET_CHOOSEN_COMPANY'
        })
    },
    mounted () {
        const self = this
        this.getUser()

        if (this.GET_PRICE_RULE) {
            this.marginInput = this.GET_PRICE_RULE.margin
            this.vatRate = this.GET_PRICE_RULE.vat
            this.rounding = this.GET_PRICE_RULE.round
        }


        if (this.$refs.wrapper && this.GET_WEBSHOP_PRODUCTS.results) {
            this.hotRef = this.$refs.wrapper.hotInstance
            self.hotRef.loadData(self.GET_WEBSHOP_PRODUCTS.results, 'edit') 
            Handsontable.renderers.registerRenderer('applyMargin', this.applyMargin)
        } 


        const updatedValue = window.localStorage.getItem('__updated_row__')

        if (!updatedValue) {
            window.localStorage.setItem('__updated_row__', [])
        } else {
            this.storageData = JSON.parse(window.localStorage.getItem('__updated_row__'))
        }


        Bus.on('search-result', e => {
            this.loading = false
            if (!e.product) {
                self.hotRef.loadData(self.GET_WEBSHOP_PRODUCTS.results, 'edit') 
            }
        })

        Bus.on('search-loader', e => {
            this.loading = true
        })
        Bus.on('reset-products', e => {
            self.$store.commit('jitcontrol/FILTER_ALL_WEBSHOP_PRODUCT', null)
            if (!e.product) {
                self.hotRef.loadData(self.GET_WEBSHOP_PRODUCTS.results, 'edit') 
            }
        })

    },
    methods : {
        searchInputTrigger : debounce(function (event) {
            if (event.target.value !== '') {
                this.filterSearch(event)
            } else {
                this.search = null
                this.show = false
                this.$store.commit('jitcontrol/FILTER_ALL_WEBSHOP_PRODUCT', null)
                this.hotRef.loadData(this.GET_WEBSHOP_PRODUCTS.results, 'edit') 
            }
        }, 500),
        filterSearch (event) {
            this.processing = true
            if (this.selectedWebshop) {
                this.search = event.target.value
                this.$store.dispatch('jitcontrol/remoteSearchProducts', { uuid : this.selectedWebshop, value : event.target.value, pricelist : this.priceListVal })
                .then(_ => {
                    this.processing = false
                    this.show = true
                    this.hotRef.loadData(this.GET_WEBSHOP_PRODUCTS.results, 'edit') 
                })
                .catch(err => { 
                    this.processing = false 
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.filterSearch(event)
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            }
        },
        getUser () {
            if (this.GET_CHOOSEN_COMPANY) {
                const payload = {
                    company : this.GET_CHOOSEN_COMPANY
                }
                this.getWebshop(payload)
            }
        },
        // getUserInformation () {
        //     this.processing = true
        //     this.$store.dispatch('jitcontrol/getUserInfo')
        //     .then(_ => { 
        //         if (this.GET_USER_INFORMATION.length > 0) {
        //             const payload = {
        //                 company : this.GET_CHOOSEN_COMPANY
        //             }
        //             this.getWebshop(payload)
        //         }
        //     })
        //     .catch(_ => {
        //         // console.log()
        //         // if (err.response.status === 401) {
        //         //     this.$store.commit('auth/CLEAR_AUTH_USER', null)
        //         //     window.Bus.$emit('sign-out')
        //         // }
        //     })
        // },
        getWebshop (data) {
            this.$store.dispatch('jitcontrol/getWebshop', data)
            .then(_ => {
                if (this.GET_WEBSHOPS.results.length > 0) {
                    this.selectedWebshop = this.GET_WEBSHOPS.results[0].uuid
                    this.getPriceList(this.GET_WEBSHOPS.results[0], data)
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.getWebshop(data)
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
            })
        },
        changeWebshop (e) {
            const payload = {
                company : this.GET_CHOOSEN_COMPANY
            }
            this.getPriceList({ uuid : this.selectedWebshop }, payload)
        },
        getPriceList (data, company) {
            this.processing = true
            this.$store.dispatch('jitcontrol/getPriceList', { uuid : data.uuid, company : company.company })
            .then(res => { 
                if (res.status === 200) {
                    this.priceListVal = res.data[0].webshop_price_list_id
                    this.getWebshopProducts(this.limit, res.data[0].webshop_price_list_id)
                }
            })
            .catch(err => { 
                this.processing = false 
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.getPriceList(data, company)
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
            })
        },
        getWebshopProducts (data = null, pricelist) {
            if (this.selectedWebshop) {
                this.loading = true
                this.$store.dispatch('jitcontrol/getWebshopProducts', { uuid : this.selectedWebshop, pricelist, limit : data })
                .then(_ => { 
                    this.loading = false
                    if (this.$refs.wrapper) {
                        this.hotRef = this.$refs.wrapper.hotInstance
                        this.hotRef.loadData(this.GET_WEBSHOP_PRODUCTS.results, 'edit') 
                        this.hotRef.beforeSetCellMeta = this.applyMargin
                        this.applyChangeColor()
                    } 
                })
                .catch(err => { 
                    this.loading = false 
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.getWebshopProducts(data, pricelist)
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            } 
        },
        changePageNumber (number) {
            this.$store.dispatch('jitcontrol/getWebshopProducts', { 
                page : number,
                uuid : this.selectedWebshop, 
                pricelist : this.priceListVal,
                limit : this.limit,
                search : this.search
            })
            .then(_ => {
                    this.hotRef.loadData(this.GET_WEBSHOP_PRODUCTS.results, 'edit') 
            }).catch((err) => {
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.changePageNumber(number)
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
            })
        },
        saveSettings () {
            if (this.webshopPriceList === 'price') {
                this.$services.helpers.notification('Select Price List', 'error', this)
            } else {
                this.updating = true

                this.priceListVal = this.webshopPriceList
                this.$store.dispatch('jitcontrol/getWebshopProducts', { 
                    uuid : this.selectedWebshop, 
                    pricelist : this.webshopPriceList,
                    limit : this.limit
                })
                .then(_ => {
                        this.hotRef.loadData(this.GET_WEBSHOP_PRODUCTS.results, 'edit') 
                        this.updating = false
                }).catch((err) => {
                    this.updating = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.saveSettings()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            }
        },
        beforeChangeHook (changes, source) {
            const self = this
            changes.forEach((change) => {
                const [row, column, oldValue, newValue] = change;

                if (parseFloat(newValue)) {
                    const obj = {}
                    if (column === 'supplier_product_price_stock.user_set_supplier_price') {
                        obj.id = row
                        obj.salesPrice = parseFloat(newValue)
                        this.$store.commit('jitcontrol/UPDATE_MARGIN', obj)
                    }
                    if (column === 'supplier_product_price_stock.supplier_price') {
                        obj.id = row
                        obj.costPrice = parseFloat(newValue)
                        this.$store.commit('jitcontrol/UPDATE_SALESPRICE_MARGIN', obj)
                    }

                    if (column === 'newSalesPrice') {
                        obj.id = row
                        obj.salesExVat = parseFloat(newValue)
                        this.$store.commit('jitcontrol/UPDATE_SALESPRICE_INCLVAT_MARGIN', obj)
                    }

                    if (column === 'margin') {
                        obj.id = row
                        obj.margin = parseFloat(newValue)
                        this.$store.commit('jitcontrol/UPDATE_SALESPRICE', obj)
                    }
                    this.storeUpdatedRow(row)
                    this.hotRef.setCellMeta(row, 6, 'className', 'colored make-me-blue');
                    this.hotRef.updateData(this.GET_WEBSHOP_PRODUCTS.results, 'edit') 
                } else {
                    this.$services.helpers.notification(this.$t('invalidCellValue'), 'error', this)
                    return false 
                }
            });
        },
        updateWebshop () {
            const selected = this.hotRef.getSelectedRange()
            if (this.selectedWebshop) {
                this.hotRef.validateColumns([3, 4, 5, 6], (valid) => {
                    console.log(valid, selected)
                    if (valid) {
                        if (selected !== undefined && selected && selected.length > 0) {
                            this.updatingWebshop = true
                            if (selected.length > 0 && selected.length < 2) {
                                if (selected[0].from.row === selected[0].to.row) {
                                    // single selected
                                    this.selectedRow.push(selected[0].from.row)
                                } else {
                                    // It a range of values
                                    this.selectedRow.push(selected[0].from.row)
                                    for (let i = 0; i <= selected[0].to.row; i++) {
                                        if (i >= selected[0].from.row && i <= selected[0].to.row) {
                                            this.selectedRow.push(i + 1)
                                        } 
                                    }
                                }
                            } else {
                                selected.forEach(element => {
                                    if (element.from.row === element.to.row) {
                                        // single selected
                                        this.selectedRow.push(element.from.row)
                                    } else {
                                        // It a range of values
                                        this.selectedRow.push(element.from.row)
                                        for (let i = 0; i <= element.to.row; i++) {
                                            if (i >= element.from.row && i <= element.to.row) {
                                                this.selectedRow.push(i + 1)
                                            } 
                                        }
                                    }
                                })
                            }

                            const row = window.localStorage.getItem('__updated_row__') // Append updates to the lists
                            
                            if (row) {
                                const rowSave = JSON.parse(row)
                                rowSave.forEach(element => {
                                    if (!this.selectedRow.includes(element.row)) {
                                        this.selectedRow.push(element.row)  
                                    }
                                })
                            }
                            
                            this.$store.dispatch('jitcontrol/updateWebshop', { uuid : this.selectedWebshop, row : this.selectedRow, pricelist : this.priceListVal, remote : true })
                            .then(res => {
                                this.updatingWebshop = false
                                this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
                                this.hotRef.deselectCell()
                                this.selectedRow = []
                                this.clearUpdatedStorage()
                            })
                            .catch(err => {
                                this.updatingWebshop = false
                                if (err.response.status === 401) {
                                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                                    .then(_ =>{
                                        this.updatingWebshop()
                                    })
                                    .catch(_ => {
                                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                        Bus.emit('sign-out')
                                    })
                                } else {
                                    this.$services.helpers.notification(err.response.data, 'error', this)
                                }
                            })
                        } else {
                            // Send updated row from localstorage
                            const row = window.localStorage.getItem('__updated_row__')
                            if (row) {
                                this.updatingWebshop = true
                                const rowSave = JSON.parse(row)
                                rowSave.forEach(element => {
                                    if (!this.selectedRow.includes(element.row)) {
                                        this.selectedRow.push(element.row)  
                                    }
                                })

                                this.$store.dispatch('jitcontrol/updateWebshop', { uuid : this.selectedWebshop, row : this.selectedRow, pricelist : this.priceListVal, remote : true })
                                .then(res => {
                                    this.updatingWebshop = false
                                    this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
                                    this.hotRef.deselectCell()
                                    this.selectedRow = []
                                    this.clearUpdatedStorage()
                                })
                                .catch(err => {
                                    this.updatingWebshop = false
                                    if (err.response.status === 401) {
                                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                                        .then(_ =>{
                                            this.updatingWebshop()
                                        })
                                        .catch(_ => {
                                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                            Bus.emit('sign-out')
                                        })
                                    } else {
                                        this.$services.helpers.notification(err.response.data, 'error', this)
                                    }
                                })
                            }
                        }
                    } else {
                        this.$services.helpers.notification(this.$t('invalidCellValue'), 'error', this)
                        return false
                    }
                })
            }
        },
        saveLocalDBPrice () {
            const selected = this.hotRef.getSelectedRange()
            if (this.selectedWebshop) {
                this.hotRef.validateColumns([3, 4, 5, 6], (valid) => {
                    if (valid) {
                        if (selected) {
                            this.savingPrice = true
                            // selected.forEach(element => {
                            //     this.selectedRow.push(element[0])
                            // })
                            if (selected.length > 0 && selected.length < 2) {
                                if (selected[0].from.row === selected[0].to.row) {
                                    // single selected
                                    this.selectedRow.push(selected[0].from.row)
                                } else {
                                    // It a range of values
                                    this.selectedRow.push(selected[0].from.row)
                                    for (let i = 0; i <= selected[0].to.row; i++) {
                                        if (i >= selected[0].from.row && i <= selected[0].to.row) {
                                            this.selectedRow.push(i + 1)
                                        } 
                                    }
                                }
                            } else {
                                selected.forEach(element => {
                                    if (element.from.row === element.to.row) {
                                        // single selected
                                        this.selectedRow.push(element.from.row)
                                    } else {
                                        // It a range of values
                                        this.selectedRow.push(element.from.row)
                                        for (let i = 0; i <= element.to.row; i++) {
                                            if (i >= element.from.row && i <= element.to.row) {
                                                this.selectedRow.push(i + 1)
                                            } 
                                        }
                                    }
                                })
                            }
                            const row = window.localStorage.getItem('__updated_row__') // Append updates to the lists
                            if (row) {
                                const rowSave = JSON.parse(row)
                                rowSave.forEach(element => {
                                    if (!this.selectedRow.includes(element.row)) {
                                        this.selectedRow.push(element.row)  
                                    }
                                })
                            }

                            this.$store.dispatch('jitcontrol/updateWebshop', { uuid : this.selectedWebshop, row : this.selectedRow, pricelist : this.priceListVal, remote : false })
                            .then(_ => {
                                this.savingPrice = false
                                this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
                                this.hotRef.deselectCell()
                                this.selectedRow = []
                                this.clearUpdatedStorage()
                            })
                            .catch(err => {
                                this.savingPrice = false
                                if (err.response.status === 401) {
                                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                                    .then(_ =>{
                                        this.saveLocalDBPrice()
                                    })
                                    .catch(_ => {
                                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                        Bus.emit('sign-out')
                                    })
                                }
                            })
                        } else {
                            // Check for updates in localstorage and send it
                            const row = window.localStorage.getItem('__updated_row__')
                            if (row) {
                                this.savingPrice = true
                                const rowSave = JSON.parse(row)
                                rowSave.forEach(element => {
                                    if (!this.selectedRow.includes(element.row)) {
                                        this.selectedRow.push(element.row)  
                                    }
                                })

                                this.$store.dispatch('jitcontrol/updateWebshop', { uuid : this.selectedWebshop, row : this.selectedRow, pricelist : this.priceListVal, remote : false })
                                .then(_ => {
                                    this.savingPrice = false
                                    this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
                                    this.hotRef.deselectCell()
                                    this.selectedRow = []
                                    this.clearUpdatedStorage()
                                })
                                .catch(err => {
                                    this.savingPrice = false
                                    if (err.response.status === 401) {
                                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                                        .then(_ =>{
                                            this.saveLocalDBPrice()
                                        })
                                        .catch(_ => {
                                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                            Bus.emit('sign-out')
                                        })
                                    }
                                })
                            }
                        }
                    } else {
                        this.$services.helpers.notification(this.$t('invalidCellValue'), 'error', this)
                        return false
                    }
                })
            }
        },
        applyAllCalculation () {
            if (this.vatRate === 'vat' || this.rounding === 'round' || this.marginInput === '') {
                this.$services.helpers.notification(this.$t('updatePriceRule'), 'error', this)
            } else if (this.vatRate === undefined || this.rounding === undefined || this.marginInput === undefined) {
                this.$services.helpers.notification(this.$t('updatePriceRule'), 'error', this)
            } else {
                this.$store.commit('jitcontrol/APPLY_ALL_CALCULATION')
                this.hotRef.loadData(this.GET_WEBSHOP_PRODUCTS.results, 'edit') 
            }
        },
        calculateSelectedItem () {
            if (this.vatRate === 'vat' || this.rounding === 'round' || this.marginInput === '') {
                this.$services.helpers.notification(this.$t('updatePriceRule'), 'error', this)
            } else if (this.vatRate === undefined || this.rounding === undefined || this.marginInput === undefined) {
                this.$services.helpers.notification(this.$t('updatePriceRule'), 'error', this)
            } else {
                const selected = this.hotRef.getSelectedRange()
                this.hotRef.validateColumns([3, 4, 5, 6], (valid) => {
                    if (valid) {
                        if (selected && selected.length > 0) {
                            this.calculating = true
                            // selected.forEach(element => {
                            //     this.selectedRow.push(element[0])
                            // })

                            if (selected.length > 0 && selected.length < 2) {
                                if (selected[0].from.row === selected[0].to.row) {
                                    // single selected
                                    this.selectedRow.push(selected[0].from.row)
                                    this.storeUpdatedRow(selected[0].from.row)
                                } else {
                                    // It a range of values
                                    this.selectedRow.push(selected[0].from.row)
                                    for (let i = 0; i <= selected[0].to.row; i++) {
                                        if (i >= selected[0].from.row && i <= selected[0].to.row) {
                                            this.selectedRow.push(i)
                                            this.storeUpdatedRow(i)
                                        } 
                                    }
                                }
                            } else {
                                selected.forEach(element => {
                                    if (element.from.row === element.to.row) {
                                        // single selected
                                        this.selectedRow.push(element.from.row)
                                        this.storeUpdatedRow(selected[0].from.row)
                                    } else {
                                        // It a range of values
                                        this.selectedRow.push(element.from.row)
                                        for (let i = 0; i <= element.to.row; i++) {
                                            if (i >= element.from.row && i <= element.to.row) {
                                                this.selectedRow.push(i + 1)
                                                this.storeUpdatedRow(i + 1)
                                            } 
                                        }
                                    }
                                })
                            }

                            this.$store.commit('jitcontrol/APPLY_SELECTED_CALCULATION', { row : this.selectedRow })
                            this.hotRef.loadData(this.GET_WEBSHOP_PRODUCTS.results, 'edit') 
                            this.hotRef.deselectCell()
                            this.applyChangeColor()
                            this.selectedRow = []
                            this.calculating = false
                        } else {
                            this.calculating = false
                        }
                    } else {
                        this.$services.helpers.notification(this.$t('invalidCellValue'), 'error', this)
                        this.calculating = false
                        return false
                    }
                })
            }
        },
        afterSelection (row, col, row2, col2, level) {
            this.showUndoBtn = true
        },
        undoSelection () {
            this.hotRef.deselectCell()
            this.showUndoBtn = false
        },
        storeUpdatedRow (row) {
            // const self = this
            const mainRowArray = []
            const saveRow = window.localStorage.getItem('__updated_row__')
            if (saveRow) {
                const parseRow = JSON.parse(saveRow)
                // Filter if exist
                const filter = parseRow.filter(item => parseInt(item.row) === parseInt(row))
                if (filter.length < 1) {
                    parseRow.push({ row : parseInt(row) })
                    this.storageData = parseRow
                    window.localStorage.setItem('__updated_row__', JSON.stringify(parseRow)) // Resaving data
                }
            } else {
                mainRowArray.push({ row : parseInt(row) })
                this.storageData = mainRowArray
                window.localStorage.setItem('__updated_row__', JSON.stringify(mainRowArray))
            }
        },
        clearUpdatedStorage () {
            window.localStorage.removeItem('__updated_row__')
            this.storageData = []
        },
        exportFileExcel () {
            this.exporting = true
            const exportFile = this.hotRef.getPlugin('exportFile')
            setTimeout(() => {
                exportFile.downloadFile('csv', {
                    bom: false,
                    columnDelimiter: ',',
                    columnHeaders: false,
                    exportHiddenColumns: true,
                    exportHiddenRows: true,
                    fileExtension: 'csv',
                    filename: 'webshop-product-CSV-file_[YYYY]-[MM]-[DD]',
                    mimeType: 'text/csv',
                    rowDelimiter: '\r\n',
                    rowHeaders: true
                })
                this.exporting = false
            }, 1000)
        },
        applyMargin (instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments)
            // Apply condition to margin
            if (col === 8) {
                if (parseInt(value, 10) < 5) {
                    td.className = 'make-me-red'
                } else {
                    td.className = 'make-me-blue'
                }
            }
        },
        shopResultNumber (e) {
            this.limit = parseInt(e.target.value)
            this.getWebshopProducts(parseInt(e.target.value), this.priceListVal)
        },
        applyChangeColor () {
            this.storageData.forEach(element => {
                this.hotRef.setCellMeta(element.row, 6, 'className', 'colored make-me-blue')
            })             
        },
        onChangeMargin (e) {
            if (isNaN(e.target.value)) {
                this.$services.helpers.notification(this.$t('invalidMarginValue'), 'error', this)
                this.marginInput = ''
            } else {
                this.marginInput = Math.abs(this.marginInput)
                this.$store.commit('jitcontrol/UPDATE_PRICE_RULE_SET', { margin : Math.abs(this.marginInput) })
            }
        },
        onChangeVat (e) {
            this.$store.commit('jitcontrol/UPDATE_PRICE_RULE_SET', { vat : this.vatRate })
        },
        onChangeRound (e) {
            this.$store.commit('jitcontrol/UPDATE_PRICE_RULE_SET', { round : this.rounding })
        },
    }
}
</script>

<style lang="scss" scoped>
@import '~handsontable/dist/handsontable.full.css';
.custom-z {
    z-index: 9999;
}
</style>