<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('warehouse') }}</div>
            <!-- <span class="bg-theme-yellow px-3 py-2 rounded-md text-white">Add Webshop</span> -->
        </div>
        <div class="mt-10">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between items-center align-items-center w-full bg-light-teal p-2 border-b">
                        <span class="text-sm font-semibold">{{ $t('warehouse') }} Information</span>
                        <span @click="addWarehouse" class="bg-theme-blue cursor-pointer text-sm px-3 py-1 rounded-md shadow-md text-white">
                            <span v-if="processing">{{ $t('connecting') }}</span>
                            <span v-else>{{ $t('addWarehouse') }}</span>
                        </span>
                    </div>
                    <div class="flex flex-col md:flex-row w-full">
                        <div class="flex flex-col w-full md:w-1/2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('warehouseName') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.name" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('address') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.address" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('city') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.city" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('country') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <select v-model="form.country" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                        <option :value="item.key" v-for="(item, i) in countries" :key="i">{{ item.name }}</option>
                                    </select>
                                    <!-- <input type="text" v-model="form.country" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;"> -->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col w-full md:w-1/2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/4 mb-2">{{ $t('zip') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.zip_code" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/4 mb-2">{{ $t('phone') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.phone" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/4 mb-2">{{ $t('note') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <textarea cols="30" v-model="form.note"  rows="5" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-10">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full bg-light-teal p-2 border-b">
                        <h2 class="text-sm font-semibold">{{ $t('warehouseOverview') }}</h2>
                    </div>
                    <div class="flex w-full">
                        <div class="block w-full overflow-x-auto md:overflow-x-hidden overflow-y-hidden">
                            <table class="table table-fixed w-full" border="4">
                                <thead>
                                    <!-- <tr>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-900">Customer Name</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="flex justify-between items-center mt-1 text-xs text-gray-900">Invoice Status <span class="flex flex-col"><span @click="sortInvoiceStatusUp" class="cursor-pointer"><img src="./../../assets/images/arrow-up.png" alt=""></span><span @click="sortInvoiceStatusDown" class="cursor-pointer"><img src="./../../assets/images/arrow-down.png" alt=""></span></span></span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="flex justify-between items-center mt-1 text-xs text-gray-900">Invoice Status <span class="flex flex-col"><span @click="sortInvoiceStatusUp" class="cursor-pointer"><img src="./../../assets/images/arrow-up.png" alt=""></span><span @click="sortInvoiceStatusDown" class="cursor-pointer"><img src="./../../assets/images/arrow-down.png" alt=""></span></span></span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-900">Action</span></th>
                                    </tr> -->
                                </thead>
                                <tbody>
                                    <tr class='p-4 border-gray-700' v-for="(warehouse, i) in GET_WAREHOUSES.results" :key="warehouse.id">
                                        <td><span class="whitespace-nowrap p-2 md:p-0 text-sm text-gray-900">{{ parseInt(i + 1) }}</span></td>
                                        <td><span class="whitespace-nowrap p-2 md:p-0 text-sm text-gray-900">{{ warehouse.name }}</span></td>
                                        <td><span @click="openUpdateModal(warehouse)" class="flex cursor-pointer items-center font-semibold space-x-4 whitespace-nowrap p-2 md:p-0 text-sm text-green-500">
                                            <span class="mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                                </svg>
                                            </span>
                                            {{ $t('update') }}
                                        </span></td>
                                        <td><span @click="deleteWarehouse(warehouse)" class="flex cursor-pointer items-center space-x-3 font-semibold whitespace-nowrap p-2 md:p-0 text-sm text-red-500">
                                            <span class="mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                            {{ $t('delete')}}
                                            </span>
                                        </td>
                                        <!-- <td>
                                            <span>
                                                <router-link :to="{ name : 'warehouse-single', params : { uuid : warehouse.uuid }}" class="bg-green-500 px-3 py-2 rounded-md text-white cursor-pointer">Enter Webshop</router-link>
                                            </span>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <ModalBox v-if="openModalBox" title="Delete Warehouse" @handleClose='openModalBox = false' :backdropOff='true'>
        <div class="flex flex-col w-full overflow-x-hidden">
            <h1 class="text-gray-500">{{ $t('removedWarehouse') }}?</h1>
            <div class="flex justify-end mt-2">
                <button @click="openModalBox = false" class="flex justify-center rounded whitespace-nowrap align-items-center  px-10 py-2 text-gray-100 text-gray-400 text-xs cursor-pointer border">Cancel</button>
                <button @click="proceedDeletion" :disabled='deleting' type="submit" class="flex justify-center rounded whitespace-nowrap align-items-center bg-red-500 px-10 py-2 text-white text-xs cursor-pointer border">
                    <span class="flex" v-if="deleting">
                        <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Deleting...
                    </span>
                    <span v-else>Yes Delete</span>
                </button>
            </div>
        </div>
    </ModalBox>
    <ModalBox v-if="openEditBox" :title="$t('updateWarehouse')" width="md:w-3/4 w-full" @handleClose='openEditBox = false'>
        <div class="flex flex-col w-full overflow-x-hidden">
            <form @submit.prevent="updateWarehouse" class="flex flex-col md:flex-row w-full">
                <div class="flex flex-col w-full md:w-1/2">
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('warehouseName') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="selectedObj.name" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('address') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="selectedObj.street_address" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('city') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="selectedObj.city" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('country') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="selectedObj.country" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                        </div>
                    </div>
                </div>
                <div class="flex flex-col w-full md:w-1/2">
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/4 mb-2">{{ $t('zip') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="selectedObj.zip_code" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/4 mb-2">{{ $t('phone') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <input type="text" v-model="selectedObj.phone" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/4 mb-2">{{ $t('note') }} <span class="text-red-500">*</span></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <textarea cols="30" v-model="selectedObj.description"  rows="5" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;"></textarea>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                        <span class="text-sm text-gray-600 w-1/4 mb-2"></span>
                        <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                            <button :disabled="updating" class="focus:outline-none bg-theme-blue text-white px-4 py-2 rounded-sm shadow-md focus:outline-none cursor-pointer">
                                <span v-if="updating">{{ $t('updating') }}</span>
                                <span v-else>{{ $t('update') }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ModalBox>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalBox from '@/components/Modal'
import { getCountries } from './helper/countries'
import Bus from '../../bus'


export default {
    name : 'webshop',
    components : { ModalBox },
    data () {
        return {
            countries : getCountries(),
            loading : true,
            openModalBox : false,
            openEditBox : false,
            processing : false,
            updating : false,
            deleting : false,
            selectedObj : {},
            form : {
                name : '',
                address : '',
                city : '',
                zip_code : '',
                country : '',
                phone : '',
                note : '',
            }
        }
    },
    computed : {
        ...mapGetters({
            USER_REFRESH : 'auth/USER_REFRESH',
            GET_WAREHOUSES : 'jitcontrol/GET_WAREHOUSES',
            GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
            GET_CHOOSEN_COMPANY : 'jitcontrol/GET_CHOOSEN_COMPANY'
        })
    },
    mounted () { this.getUser() },
    methods : {
        getUser () {
            if (this.GET_CHOOSEN_COMPANY) {
                this.getWarehouse()
            }
        },
        getWarehouse () {
            if (this.GET_CHOOSEN_COMPANY) {
                const payload = {
                    company : this.GET_CHOOSEN_COMPANY
                }
                this.$store.dispatch('jitcontrol/getWarehouse', payload)
                .then(_ => {
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.getWarehouse()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            }
        },
        addWarehouse () {
            if (this.form.name === '' || this.form.address === '' || this.form.city === '' || this.form.zip_code === '' ||
            this.form.country === '' || this.form.phone === '' || this.form.note === '') {
                this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
            } else {
                this.processing = true
                const payload = {
                    name: this.form.name,
                    street_address: this.form.address,
                    zip_code: this.form.zip_code,
                    city: this.form.city,
                    country: this.form.country,
                    description: this.form.note,
                    phone: this.form.phone,
                    company : this.GET_CHOOSEN_COMPANY
                }
                this.$store.dispatch('jitcontrol/addWarehouse', payload)
                .then(res => {
                    this.processing = false
                    Object.keys(this.form).forEach(item => { this.form[item] = '' })
                    this.$services.helpers.notification('Warehouse added successfully', 'success', this)
                })
                .catch(err => {
                    this.processing = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.addWarehouse()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    } else {
                        this.$services.helpers.notification(err.response.data, 'error', this)
                    }
                })
            }
        },
        updateWarehouse () {
            if (this.selectedObj.name === '' || this.selectedObj.street_address === '' || this.selectedObj.city === '' || this.selectedObj.zip_code === '' ||
            this.selectedObj.country === '' || this.selectedObj.phone === '' || this.selectedObj.description === '') {
                this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
            } else {
                this.updating = true
                const payload = {
                    name: this.selectedObj.name,
                    street_address: this.selectedObj.address,
                    zip_code: this.selectedObj.zip_code,
                    city: this.selectedObj.city,
                    country: this.selectedObj.country,
                    description: this.selectedObj.description,
                    phone: this.selectedObj.phone,
                    uuid : this.selectedObj.uuid,
                    company : this.GET_CHOOSEN_COMPANY
                }
                this.$store.dispatch('jitcontrol/UpdateWarehouse', payload)
                .then(res => {
                    this.updating = false
                    this.openEditBox = false
                    this.$services.helpers.notification(this.$t('updatedWarehouse'), 'success', this)
                })
                .catch(err => {
                    this.updating = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.updateWarehouse()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    } else {
                        this.$services.helpers.notification(err.response.data.message, 'error', this)
                    }
                })
            }
        },
        openUpdateModal (data) {
            this.selectedObj = data
            this.openEditBox = true
        },
        proceedDeletion () {
            this.deleting = true
            const payload = {
                URL : this.$services.endpoints.WAREHOUSE_ENDPOINT + `/${this.selectedObj.uuid}`
            }
            this.$store.dispatch('jitcontrol/customDeleteRequest', payload)
            .then(res => {
                this.deleting = false
                this.getWarehouse()
                this.$services.helpers.notification('Warehouse deleted successfully', 'success', this)
                this.openModalBox = false
                this.selectedObj = {}
            })
            .catch(err => {
                this.deleting = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.proceedDeletion()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                } else {
                    this.$services.helpers.notification(err.response.data.message, 'error', this)
                }
            })
        },
        deleteWarehouse (data) {
            this.selectedObj = data
            this.openModalBox = true
        }
    }
}
</script>

<style lang="scss" scoped>
.table th {
    padding: .3rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.table tbody, td {
    padding: .7rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6 !important;
}
</style>