<template>
    <td><input v-model="webshopProduct.checked" :value="webshopProduct.checked" @change="checkboxOrder" class="checkbox"  type="checkbox"/></td>
    <td>{{ webshopProduct.product.sku }}</td>
    <td>{{ webshopProduct.product.name }}</td>
    <td>{{ webshopProduct.supplier_product_price_stock.supplier_quantity }}</td>
    <td>{{ webshopProduct.product.vendor_number }}</td>
    <td>{{ webshopProduct.supplier_product_price_stock.supplier_price }}</td>
    <td>{{ webshopProduct.supplier_product_price_stock.user_set_supplier_price }}</td>
    <td>
        <span class="font-black text-red-500" v-if="webshopProduct.supplier_product_price_stock.discontinued">{{ $t('discontinued') }}</span>
        <span class="font-black text-green-500" v-else>{{ $t('available') }}</span>
    </td>
    <td>
        <!-- <span class="cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
        </span> -->
        <div class="flex relative space-x-3 font-semibold whitespace-nowrap p-2 md:p-0 text-sm text-gray-400">
            <div class="absolute w-24 bg-white z-50 mt-2 -ml-5 rounded border shadow top-rem" v-if="showActionBox">
                <div>
                    <ul>
                        <li @click="singleDeleteProduct(webshopProduct)" class="hover:bg-deep-teal cursor-pointer hover:text-theme-blue">
                            <span class="flex items-center space-x-1 p-2">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </span>
                                <span class="text-xs hover:text-theme-blue hover:font-black">{{ $t('delete') }}</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <span class="mr-2 cursor-pointer" @click="showActionBox = true">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                </svg>
            </span>
        </div>
    </td>
</template>

<script>
import Bus from '../../../bus'
    export default {
        name : 'ProductTable',
        props : {
            product : {
                type : Object,
                require : true,
            }
        },
        emits : ['singleDelete', 'toggleEvent'],
        data () {
            return {
                webshopProduct : this.product,
                checked : false,
                showActionBox : false,
            }
        },
        watch : {
            product : function (n, o) {
                if (n) {
                    this.webshopProduct = this.product
                }
            },
            showActionBox : function (n, o) {
                if (n) {
                    this.$emit('toggleEvent', true)
                }
            }
        },
        mounted () {
            Bus.on('removeActionBox', e => {
                this.showActionBox = false
            })
        },
        methods : {
            checkboxOrder (e) {
                this.$store.commit('jitcontrol/TOGGLE_WEBSHOP_PRODUCT_CHECKBOX', { uuid : this.webshopProduct.uuid, value : this.webshopProduct.checked })   
                // if (this.webshopProduct.supplier_product_price_stock.discontinued) {
                // } else {
                //     this.webshopProduct.checked = false
                //     this.checked = !e.target.value
                // }
            },
            singleDeleteProduct (data) {
                this.$emit('singleDelete', data)
            }
        }

    }
</script>

<style lang="scss" scoped>
.table th {
    padding: .3rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.table tbody, td {
    padding-top: .4rem;
    padding-bottom: .4rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6 !important;
}
</style>