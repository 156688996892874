<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('overview')}}</div>
        </div>
        <div class="mt-5">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="flex justify-between align-items-center space-x-4 graph-1 shadow-sm px-3 py-3 rounded-md shadow-sm">
                    <div class="flex flex-col w-1/2">
                        <div class="flex justify-center text-gray-400">
                            <img class="h-10 w-10" src="./../../assets/images/dash/lock.png" alt="">
                        </div>
                        <div class="text-center text-sm text-gray-400 py-2">{{ $t('totalMonth') }}</div>
                        <div class="text-center font-semibold text-2xl text-gray-900 py-2">30,800</div>
                    </div>
                    <div class="flex justify-center w-1/2">
                        <div class="">
                            <img src="./../../assets/images/dash/graph.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="flex justify-between align-items-center space-x-4 graph-2 shadow-sm px-3 py-3 rounded-md shadow-sm">
                    <div class="flex flex-col w-1/2">
                        <div class="flex justify-center text-gray-400">
                            <img class="h-10 w-10" src="./../../assets/images/dash/lock.png" alt="">
                        </div>
                        <div class="text-center text-sm text-gray-400 py-2">{{ $t('totalMonth') }}</div>
                        <div class="text-center font-semibold text-2xl text-gray-900 py-2">30,800</div>
                    </div>
                    <div class="flex justify-center w-1/2">
                        <div class="">
                            <img src="./../../assets/images/dash/graph.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="flex justify-between align-items-center space-x-4 graph-3 shadow-sm px-3 py-3 rounded-md shadow-sm">
                    <div class="flex flex-col w-1/2">
                        <div class="flex justify-center text-gray-400">
                            <img class="h-10 w-10" src="./../../assets/images/dash/lock.png" alt="">
                        </div>
                        <div class="text-center text-sm text-gray-400 py-2">{{ $t('totalMonth') }}</div>
                        <div class="text-center font-semibold text-2xl text-gray-900 py-2">30,800</div>
                    </div>
                    <div class="flex justify-center w-1/2">
                        <div class="">
                            <img src="./../../assets/images/dash/graph.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-10">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="md:col-span-2 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full box-header p-2 border-b">
                        <h2 class="text-sm">Sale Statistics</h2>
                        <div class="flex justify-between">
                            <!-- <span>sdfs</span>
                            <span>sdfs</span> -->
                        </div>
                    </div>
                    <div class="flex flex-col p-5 w-full">
                        <apexchart height="250" type="bar" :options="chartOptions" :series="series"></apexchart>
                    </div>
                </div>
                <div class="flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full box-header p-2 border-b">
                        <h2 class="text-sm">Top Countries</h2>
                    </div>
                    <div class="flex flex-col p-20">
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-10">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full box-header p-2 border-b">
                        <h2 class="text-sm">Sale Statistics</h2>
                        <div class="flex justify-between">
                            <!-- <span>sdfs</span>
                            <span>sdfs</span> -->
                        </div>
                    </div>
                    <div class="flex flex-col p-20">
                        
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    // import PageTopNav from './components/PageTopNav'

    export default {
        name : 'Dashboard',
        // components : { PageTopNav }
        data () {
            return {
                chartOptions : {
                    chart: {
                        type: 'bar',
                        stacked: true,
                        height: 350,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                    },
                    yaxis: {
                        title: {
                            text: '$ (thousands)'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            return "$ " + val + " thousands"
                            }
                        }
                    }
                },
                series: [
                    {
                        name: 'Net Profit',
                        data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
                    }, 
                    {
                        name: 'Revenue',
                        data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
                    }, 
                    
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
.graph-1 {
    background: #EBFDEF;
}
.graph-2 {
    background: #E8EFF9;
}
.graph-3 {
    background: #FFEFE7;
}
.box-header {
    background: #F4F8FF;
}
</style>