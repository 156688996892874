<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('addWebshop') }}</div>
        </div>
        
        <div class="absolute bg-red-30 inset-0 z-10" @click="removeModal" v-if="modal"></div>
        <div class="absolute bg-red-30 inset-0 z-10" @click="removeModal" v-if="countryModal"></div>

        <div class="mt-10">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full bg-light-teal p-2 border-b">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 md:gap-10">
                            <h2 @click="tab = 1" class="text-sm cursor-pointer">{{ $t('webshopInformation') }}</h2>
                            <h2 @click="tab = 2" class="text-sm cursor-pointer md:mt-0">{{ $t('orderSettings') }}</h2>
                        </div>

                    </div>
                    <form v-if="tab === 1" @submit.prevent="moveNexStep" class="flex flex-col md:flex-row w-full">
                        <div class="flex flex-col w-full md:w-1/2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('shopName') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.name" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('dropship') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.dropship" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('country') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <!-- <select v-model="form.country" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                        <option :value="item.key" v-for="(item, i) in countries" :key="i">{{ item.name }}</option>
                                    </select> -->
                                    <input type="text" v-model="countryText" @input="searchCountry" @focus="countryModal = true" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                    <div class="relative">
                                        <div v-if="countryModal && countryResults.length > 0" class="absolute w-56 bg-white z-50 rounded border shadow-xl top-rem">
                                            <ul>
                                                <li class="py-1 pl-2 hover:bg-gray-100 text-gray-700 cursor-pointer text-sm" v-for="(item, i) in countryResults.slice(0, 5)" :key="i" @click="setCountry(item)">{{ item.name }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('shopPhone') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.phone" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('shopEmail') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="email" v-model="form.shopEmail" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('shopURL') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.shopUrl" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('apiStore') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.apiStoreName" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('clientSecret') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.secret" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('clientID') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.clientId" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('sendEmailTrackingAlert') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="checkbox" class="checkbox" v-model="form.emailTracking">
                                </div>
                            </div>
                            <!-- <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">Logo<span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.logo" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div> -->
                        </div>
                        <div class="flex flex-col w-full md:w-1/2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailServer') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.server" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailSender') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.emailSender" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailPassword') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="password" v-model="form.emailPassword" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailPort') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.port" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailCopy') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.emailCC" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailSubject') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.subject" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2"></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <div class="flex mb-2">
                                        <button :disabled="processing" class="bg-theme-blue text-white px-4 py-1 rounded-md shadow-md focus:outline-none cursor-pointer">
                                           <span v-if="processing">{{ $t('adding') }}</span>
                                           <span v-else>{{ $t('continue') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/4 mb-2">Note <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <textarea cols="30" v-model="form.note"  rows="5" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required></textarea>
                                </div>
                            </div> -->
                        </div>
                    </form>
                    <form  v-if="tab === 2" @submit.prevent="addWebshop" class="flex flex-col md:flex-row w-full">
                        <div class="flex flex-col w-full md:w-1/2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('startTime') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input v-model="form.post_order_start_time" type="time" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('stopTime') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input v-model="form.post_order_end_time" type="time" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('timezone') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <!-- <select v-model="form.post_order_timezone" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                        <option :value="item.value" v-for="(item, i) in timezone" :key="i">{{ item.name }}</option>
                                    </select> -->
                                    <input v-model="timezoneText" @input="searchField" @focus="modal = true" type="text" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                    <div v-if="modal && searchResults.length > 0" class="absolute w-56 bg-white z-50 rounded border shadow-xl top-rem">
                                        <ul>
                                            <li class="py-1 pl-2 hover:bg-gray-100 text-gray-700 cursor-pointer text-sm" v-for="(item, i) in searchResults.slice(0, 5)" :key="i" @click="setTimeZone(item)">{{ item.name }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 mt-10 mb-20 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2"></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <button type="submit" :disabled="processing" class="bg-theme-blue text-white px-4 py-1 rounded-md shadow-md focus:outline-none cursor-pointer w-full">
                                        <span v-if="processing">{{ $t('saving') }}</span>
                                        <span v-else>{{ $t('save') }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="mt-10 mb-20">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full bg-light-teal p-2 border-b">
                        <h2 class="text-sm">{{ $t('webshopOverview') }}</h2>
                    </div>
                    <div class="flex w-full">
                        <div class="block w-full overflow-x-auto md:overflow-x-hidden overflow-y-hidden">
                            <table class="table table-fixed w-full" border="4">
                                <thead>
                                    <!-- <tr>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-900">Customer Name</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="flex justify-between items-center mt-1 text-xs text-gray-900">Invoice Status <span class="flex flex-col"><span @click="sortInvoiceStatusUp" class="cursor-pointer"><img src="./../../assets/images/arrow-up.png" alt=""></span><span @click="sortInvoiceStatusDown" class="cursor-pointer"><img src="./../../assets/images/arrow-down.png" alt=""></span></span></span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="flex justify-between items-center mt-1 text-xs text-gray-900">Invoice Status <span class="flex flex-col"><span @click="sortInvoiceStatusUp" class="cursor-pointer"><img src="./../../assets/images/arrow-up.png" alt=""></span><span @click="sortInvoiceStatusDown" class="cursor-pointer"><img src="./../../assets/images/arrow-down.png" alt=""></span></span></span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-900">Action</span></th>
                                    </tr> -->
                                </thead>
                                <tbody>
                                    <tr v-for="webshop in GET_WEBSHOPS.results" :key="webshop.id">
                                        <td>
                                            <span>
                                                <img class="h-28 w-32 object-contain" :src="webshop.company_logo" alt="webshop | jitcontrol" v-if="webshop.company_logo">
                                                <!-- <img class="h-28 w-32 object-contain" src="./../../assets/images/webshop.png" alt="webshop | jitcontrol" v-else> -->
                                            </span>
                                        </td>
                                        <td><span class="whitespace-nowrap p-2 md:p-0 text-sm text-gray-900">{{ webshop.shop_name }}</span></td>
                                        <td><span class="flex items-center cursor-pointer font-semibold space-x-4 whitespace-nowrap p-2 md:p-0 text-sm text-green-500">
                                            <span class="mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                                </svg>
                                            </span>
                                            {{ $t('update') }}
                                        </span></td>
                                        <td><span class="flex items-center cursor-pointer space-x-3 font-semibold whitespace-nowrap p-2 md:p-0 text-sm text-red-500">
                                            <span class="mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                            {{ $t('disconnect') }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Bus from '../../bus'
import { getCountries } from './helper/countries'
import { getTimeZone } from './helper/timezone'

export default {
    name : 'add-webshop',
    data () {
        return {
            processing : false,
            timezone : getTimeZone(),
            tab : 1,
            form : {
                name : '',
                dropship : '',
                country : '',
                shopUrl : '',
                dropshipOrders : false,
                emailTracking : false,
                phone : '',
                shopEmail : '',
                apiStoreName : '',
                secret : '',
                clientId : '',
                logo : '',
                server : '',
                emailSender : '',
                emailPassword : '',
                port : '',
                emailCC : '',
                subject : '',
                post_order_start_time : '',
                post_order_end_time : '',
                post_order_timezone : '',
            },
            countries : getCountries(),
            modal : false,
            countryModal : false,
            searchResults : [],
            countryResults : [],
            countryText : '',
            timezoneText : ''
        }
    },
    computed : {
        ...mapGetters({
            USER_REFRESH : 'auth/USER_REFRESH',
            GET_WEBSHOPS : 'jitcontrol/GET_WEBSHOPS',
            GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
            GET_CHOOSEN_COMPANY : 'jitcontrol/GET_CHOOSEN_COMPANY'
        })
    },
    mounted () {
        this.getUser()
    },
    methods : {
        getUser () {
            if (this.GET_CHOOSEN_COMPANY) {
                this.loading = true
                const payload = {
                    company : this.GET_CHOOSEN_COMPANY
                }
                this.getWebshop(payload)
            }
        },
        // getUserInformation () {
        //     this.loading = true
        //     this.$store.dispatch('jitcontrol/getUserInfo')
        //     .then(_ => { 
        //         if (this.GET_USER_INFORMATION.length > 0) {
        //             const payload = {
        //                 company : this.GET_CHOOSEN_COMPANY
        //             }
        //             this.getWebshop(payload)
        //         }
        //     })
        //     .catch(_ => {})
        // },
        getWebshop (data) {
            this.loading = true
            this.$store.dispatch('jitcontrol/getWebshop', data)
            .then(_ => {
                this.loading = false
            })
            .catch(err => {
                this.loading = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.getWebshop(data)
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
            })
        },
        addWebshop () {
            this.processing = true
            const payload = {
                // contact_email: this.form.country,
                contact_phone: this.form.phone,
                country_code: this.form.country,
                shop_owner_email: this.form.shopEmail,
                email_server: this.form.server,
                password: this.form.emailPassword,
                sender: this.form.emailSender,
                incoming_port: this.form.port,
                email_subject: this.form.subject,
                shop_name: this.form.name,
                shop_url: this.form.shopUrl,
                cc_email_receiver: this.form.emailCC,
                email_tracking_alerts_to_buyer: this.emailTracking,
                post_order_start_time : this.post_order_start_time,
                post_order_end_time : this.post_order_end_time,
                post_order_timezone : this.post_order_timezone,
            }
            this.$store.dispatch('jitcontrol/addWebshop', payload)
            .then(res => {
                this.processing = false
                this.getWebshop()
                Object.keys(this.form).forEach(item => { this.form[item] = '' })
                this.$services.helpers.notification(this.$t('success'), 'success', this)
            })
            .catch(err => {
                this.processing = false
                // if (err.response.status === 401) {
                //     window.Bus.$emit('sign-out')
                // }
                if (err.response.status === 400) {
                    this.$services.helpers.notification(err.response.data, 'error', this)
                }
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.addWebshop()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
            })
        },
        searchField () {
            this.searchResults = this.timezone.filter(zone => {
                return String(zone.key.toLowerCase()).includes(this.timezoneText.toLowerCase())
            })
        },
        setTimeZone (item) {
            this.timezoneText = item.key
            this.form.post_order_timezone = item.key
            this.modal = false
        },
        setCountry (item) {
            this.countryText = item.name
            this.form.country_code = item.key
            this.countryModal = false
        },
        searchCountry () {
            this.countryResults = this.countries.filter(country => {
                return String(country.key.toLowerCase()).includes(this.countryText.toLowerCase())
            })
        },
        removeModal () {
            this.modal = false
            this.countryModal = false
        },
        moveNexStep () {
            this.tab = 2
        }
    }
}
</script>

<style lang="scss" scoped>

</style>