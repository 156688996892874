<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('productOverview') }}</div>
            <select v-model="selectedWebshop" @change="changeWebshop" class="px-5 py-1 rounded-sm focus:outline-none bg-white border text-xs text-gray-700">
                <option :value="webshop.uuid" v-for="webshop in GET_WEBSHOPS.results" :key="webshop.uuid">{{ webshop.shop_name }}</option>
            </select>
        </div>

        <div class="mt-10">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between items-center align-items-center w-full bg-light-teal p-1 border-b">
                        <span class="text-sm font-semibold">{{ $t('product') }}</span>
                        <!-- <span class="bg-theme-blue text-sm px-3 py-1 rounded-md shadow-md text-white">{{ $t('product') }}</span> -->
                    </div>
                    <div class="flex flex-col md:flex-row w-full">
                        <div class="flex flex-col w-full md:w-1/2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('webshopPricelist') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <select @change="refetchWebshopPrice" :disabled='gettingProduct' v-model="webshopPriceList" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                        <option :value='priceList.webshop_price_list_id' v-for="priceList in GET_PRICE_LIST" :key="priceList.uuid">{{ priceList.webshop_price_list_id }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="absolute bg-red-30 inset-0 z-10" @click="removeEvent" v-if="showActionBox"></div>
        <div class="absolute bg-red-30 inset-0 z-10" @click="showFilter = false" v-if="showFilter"></div>

        <div class="mt-10 mb-20">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between items-center w-full bg-light-teal p-2 border-b">
                        <h2 class="text-sm font-semibold">{{ $t('productOverview')}}</h2>
                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4">
                            <div class="flex items-center space-x-3">
                                <button v-if="GET_WEBSHOP_PRODUCT_CHECKED && GET_WEBSHOP_PRODUCT_CHECKED.status" @click="proceedMassDelete(GET_WEBSHOP_PRODUCT_CHECKED.data)" class="flex items-center space-x-1 focus:outline-none whitespace-nowrap bg-red-500 text-xs px-3 py-2 rounded-md shadow-md text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                    </svg>
                                   <span>{{ $t('massDelete') }}</span>
                                </button>
                                <div class="flex justify-end w-full relative">
                                    <button class="flex items-center md:space-x-3 text-xs bg-white rounded p-2 border focus:outline-none" @click="showFilter = !showFilter">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                                            </svg>
                                        </span>
                                        <span class="whitespace-nowrap">{{ $t('filter') }}</span>
                                    </button>
                                    <div class="absolute w-52 bg-white custom-z rounded border shadow mt-0 md:-ml-20" v-if="showFilter">
                                        <FilterComponent  @hideAdvanceFilter="showFilter = false"
                                            :pricelist="parseInt(priceListVal)" 
                                            :sub="selectedWebshop"
                                            :product="true"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <input :placeholder="$t('search')" @input="searchInputTrigger" type="text" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full">
                        <div class="block w-full overflow-x-auto md:overflow-x-hidden overflow-y-hidden">
                            <span v-if="loading" class="flex justify-center w-full">
                                <svg class="text-center animate-spin mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </span>
                            <table :class="GET_WEBSHOP_PRODUCTS && GET_WEBSHOP_PRODUCTS.results.length > 0 ? 'mb-4' : ''" class="table table-fixed w-full" border="4">
                                <thead>
                                    <tr>
                                        <th width="30px" class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400"><input type="checkbox" v-model="checkboxAll" @change="toggleAllCheckbox" class="checkbox"></span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="flex justify-between items-center text-xs text-gray-400">SKU <span class="flex flex-col"><span @click="sortWebshopProductSKUAsc" class="cursor-pointer"><img src="./../../assets/images/arrow-up.png" alt=""></span><span @click="sortWebshopProductSKUDesc" class="cursor-pointer"><img src="./../../assets/images/arrow-down.png" alt=""></span></span></span></th>
                                        <th width="30%" class="text-left whitespace-nowrap p-2 md:p-0"><span class="flex justify-between text-xs text-gray-400 px-2">{{ $t('name') }} <span class="flex flex-col"><span @click="sortWebshopProductNameAsc" class="cursor-pointer"><img src="./../../assets/images/arrow-up.png" alt=""></span><span @click="sortWebshopProductNameDesc" class="cursor-pointer"><img src="./../../assets/images/arrow-down.png" alt=""></span></span></span></th>
                                        <th width="8%" class="text-left whitespace-nowrap p-2 md:p-0"><span class="flex justify-between items-center text-xs text-gray-400 px-1">{{ $t('quantity') }} <span class="flex flex-col"><span @click="sortWebshopProductQTYAsc" class="cursor-pointer"><img src="./../../assets/images/arrow-up.png" alt=""></span><span @click="sortWebshopProductQTYDesc" class="cursor-pointer"><img src="./../../assets/images/arrow-down.png" alt=""></span></span></span></th>
                                        <th width="15%" class="text-left whitespace-nowrap p-2 md:p-0"><span class="flex justify-between items-center text-xs text-gray-400 px-1">{{ $t('vendorNumber') }}</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="flex justify-between items-center text-xs text-gray-400 px-1">{{ $t('costPrice') }} <span class="flex flex-col"><span @click="sortWebshopProductCostPriceAsc" class="cursor-pointer"><img src="./../../assets/images/arrow-up.png" alt=""></span><span @click="sortWebshopProductCostPriceDesc" class="cursor-pointer"><img src="./../../assets/images/arrow-down.png" alt=""></span></span></span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="flex justify-between items-center text-xs text-gray-400 px-1">{{ $t('salesPrice') }} <span class="flex flex-col"><span @click="sortWebshopProductSalesAsc" class="cursor-pointer"><img src="./../../assets/images/arrow-up.png" alt=""></span><span @click="sortWebshopProductSalesDesc" class="cursor-pointer"><img src="./../../assets/images/arrow-down.png" alt=""></span></span></span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t('status') }}</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t('action') }}</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-xs text-left text-gray-600" v-for="product in GET_WEBSHOP_PRODUCTS.results" :key="product.uuid">
                                        <ProductOverviewTable 
                                            :product="product" 
                                            @toggleEvent="toggleEventAction"
                                            @singleDelete='singleDeleteProduct' 
                                            
                                        />                                          
                                    </tr>
                                </tbody>
                            </table>
                            <div class="flex justify-between p-2 text-xs mb-20">
                                <div class="flex">
                                    <select @change="shopResultNumber" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                        <option selected disabled>{{ $t('show') }}</option>
                                        <option :value="i*100" v-for="i in 5" :key="i">{{ i*100 }}</option>
                                    </select>
                                </div>
                                <div class="flex" v-if="GET_WEBSHOP_PRODUCTS">
                                    <v-pagination
                                        v-model="GET_WEBSHOP_PRODUCTS.page_number"
                                        :pages="GET_WEBSHOP_PRODUCTS.total_pages"
                                        :range-size="1"
                                        active-color="#DCEDFF"
                                        @update:modelValue="changePageNumber"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <ModalBox v-if="openDeletedModal" :title="$t('deleteWebshopOrder')" @handleClose='openDeletedModal = false' :backdropOff='false'>
        <div class="flex flex-col w-full overflow-x-hidden">
            <h1 class="text-gray-500">{{ $t('removeProducts') }}?</h1>
            <div class="flex justify-end mt-2">
                <button @click="openDeletedModal = false" class="flex justify-center rounded whitespace-nowrap align-items-center  px-10 py-2 text-gray-100 text-gray-400 text-xs cursor-pointer border">{{ $t('cancel') }}</button>
                <button @click="proceedDeletion" :disabled='deleting' type="submit" class="flex justify-center rounded whitespace-nowrap align-items-center bg-red-500 px-10 py-2 text-white text-xs cursor-pointer border">
                    <span class="flex" v-if="deleting">
                        <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {{ $t('deleting') }}
                    </span>
                    <span v-else>{{ $t('yesDelete') }}</span>
                </button>
            </div>
        </div>
    </ModalBox>
</template>

<script>
    import { mapGetters } from 'vuex'
    import debounce from 'lodash.debounce'
    import ModalBox from '@/components/Modal'
    import ProductOverviewTable from './minicomponent/ProductOverviewTable'
    import FilterComponent from './minicomponent/FilterWebshopProduct'
    import VPagination from '@hennge/vue3-pagination'
    import '@hennge/vue3-pagination/dist/vue3-pagination.css'
    import Bus from '../../bus'

    export default {
        name : 'webshop',
        components : { ModalBox, FilterComponent, VPagination, ProductOverviewTable },
        data () {
            return {
                webshopPriceList : 'price',
                selectedWebshop : null,
                showActionBox : false,
                openDeletedModal : false,
                loading : true,
                showFilter : false,
                processing : false,
                priceListVal : null,
                limit : 100,
                checkboxAll : false,
                gettingProduct : false,
                show : false,
                search : null,
                deleting : false,
                selectedData : []
            }
        },
        watch : {
            priceListVal : function (n, o) {
                if (n) {
                    this.webshopPriceList = this.priceListVal
                }
            },
            showFilter  : function (newVl, _) {
                if (newVl === false) {
                    // this.hotRef.loadData(this.GET_WEBSHOP_PRODUCTS.results, 'edit') 
                }
            },
        },
        computed : {
            ...mapGetters({
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_WEBSHOP_PRODUCTS : 'jitcontrol/GET_WEBSHOP_PRODUCTS',
                GET_WEBSHOP_PRODUCT_CHECKED : 'jitcontrol/GET_WEBSHOP_PRODUCT_CHECKED',
                GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
                GET_PRICE_LIST : 'jitcontrol/GET_PRICE_LIST',
                GET_WEBSHOPS : 'jitcontrol/GET_WEBSHOPS',
                GET_WAREHOUSES : 'jitcontrol/GET_WAREHOUSES',
                GET_CHOOSEN_COMPANY : 'jitcontrol/GET_CHOOSEN_COMPANY'
            })
        },
        mounted () {
            this.getUser()
            Bus.on('search-loader', e => {
                this.loading = true
            })

            Bus.on('search-result', e => {
                this.loading = false
            })
        },
        methods : {
            searchInputTrigger : debounce(function (event) {
                if (event.target.value !== '') {
                    this.filterSearch(event)
                } else {
                    this.search = null
                    this.show = false
                    this.$store.commit('jitcontrol/FILTER_ALL_WEBSHOP_PRODUCT', null)
                }
            }, 500),
            filterSearch (event) {
                this.loading = true
                if (this.selectedWebshop) {
                    this.search = event.target.value
                    this.$store.dispatch('jitcontrol/remoteSearchProducts', { uuid : this.selectedWebshop, value : event.target.value, pricelist : this.priceListVal })
                    .then(_ => {
                        this.loading = false
                        this.show = true
                    })
                    .catch(err => { 
                        this.loading = false
                        if (err.response.status === 401) {
                            this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                            .then(_ =>{
                                this.filterSearch(event)
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                Bus.emit('sign-out')
                            })
                        } 
                    })
                }
            },
            getUser () {
                if (this.GET_CHOOSEN_COMPANY) {
                    this.processing = true
                    const payload = {
                        company : this.GET_CHOOSEN_COMPANY
                    }
                    this.getWebshop(payload)
                }
            },
            // getUserInformation () {
            //     this.processing = true
            //     this.$store.dispatch('jitcontrol/getUserInfo')
            //     .then(_ => { 
            //         if (this.GET_USER_INFORMATION.length > 0) {
            //             const payload = {
            //                 company : this.GET_USER_INFORMATION[0].company ? this.GET_USER_INFORMATION[0].company.uuid : this.GET_USER_INFORMATION[0].user.company.uuid
            //             }
            //             this.getWebshop(payload)
            //         }
            //     })
            //     .catch(_ => {})
            // },
            getWebshop (data) {
                this.$store.dispatch('jitcontrol/getWebshop', data)
                .then(_ => {
                    if (this.GET_WEBSHOPS.results.length > 0) {
                        this.selectedWebshop = this.GET_WEBSHOPS.results[0].uuid
                        this.getPriceList(this.GET_WEBSHOPS.results[0], data)
                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.getWebshop(data)
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            },
            changeWebshop (e) {
                const payload = {
                    company : this.GET_USER_INFORMATION[0].company ? this.GET_USER_INFORMATION[0].company.uuid : this.GET_USER_INFORMATION[0].user.company.uuid
                }
                this.getPriceList({ uuid : this.selectedWebshop }, payload)
            },
            getPriceList (data, company) {
                this.processing = true
                this.$store.dispatch('jitcontrol/getPriceList', { uuid : data.uuid, company : company.company })
                .then(res => { 
                    if (res.status === 200) {
                        this.priceListVal = res.data[0].webshop_price_list_id
                        this.getWebshopProducts(this.limit, res.data[0].webshop_price_list_id)
                    }
                })
                .catch(err => { 
                    this.processing = false 
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.getPriceList(data, company)
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            },
            getWebshopProducts (data = null, pricelist) {
                if (this.selectedWebshop) {
                    this.loading = true
                    this.$store.dispatch('jitcontrol/getWebshopProducts', { uuid : this.selectedWebshop, pricelist, limit : data })
                    .then(_ => { 
                        this.loading = false 
                    })
                    .catch(err => { 
                        this.loading = false
                        if (err.response.status === 401) {
                            this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                            .then(_ =>{
                                this.getWebshopProducts(data, pricelist)
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                Bus.emit('sign-out')
                            })
                        } 
                    })
                } 
            },
            changePageNumber (number) {
                this.loading = true
                this.$store.dispatch('jitcontrol/getWebshopProducts', { 
                    page : number,
                    uuid : this.selectedWebshop, 
                    pricelist : this.priceListVal,
                    limit : this.limit,
                    search : this.search
                })
                .then(_ => {
                    this.loading = false
                        // this.hotRef.loadData(this.GET_WEBSHOP_PRODUCTS.results, 'edit') 
                }).catch((err) => {
                    this.loading = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.changePageNumber(number)
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            },
            refetchWebshopPrice (e) {
                this.gettingProduct = true
                this.loading = true
                this.priceListVal = this.webshopPriceList
                this.$store.dispatch('jitcontrol/getWebshopProducts', { 
                    uuid : this.selectedWebshop, 
                    pricelist : this.webshopPriceList,
                    limit : this.limit
                })
                .then(_ => {
                    this.loading = false
                    this.gettingProduct = false
                }).catch((err) => {
                    this.gettingProduct = false
                    this.loading = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.refetchWebshopPrice(e)
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            },
            getDiscontinuedProduct (data) {
                this.gettingProduct = true
                const payload = {
                    id : this.selectedWebshop, 
                    pricelist : this.webshopPriceList,
                    query : `?discontinued=${data}&limit=${this.limit}`
                }
                this.$store.dispatch('jitcontrol/getWebshopProductSearch', payload)
                .then()
                .catch(err => {
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.getDiscontinuedProduct(data)
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            },
            proceedDeletion () {
                this.deleting = true
                const productArray = []

                this.selectedData.forEach(element => {
                    productArray.push(element.supplier_product_price_stock.uuid)
                })

                const payload = {
                    ids : productArray
                }

                this.$store.dispatch('jitcontrol/deleteWebshopBulkProductStock', { subscriptionId : this.selectedWebshop, product : payload })
                .then(_ => {
                    this.deleting = false
                    this.openDeletedModal = false
                    this.selectedData = []
                    this.$services.helpers.notification(this.$t('deletedProductSuccess'), 'success', this)
                }).catch((err) => {
                    this.deleting = false
                    if (err.status === 400) {
                        this.$services.helpers.notification('Oops...Error', 'error', this)
                    }
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.proceedDeletion()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            },
            toggleAllCheckbox (e) {
                this.$store.commit('jitcontrol/TOGGLE_ALL_PRODUCT_CHECKBOX', this.checkboxAll)
            },
            singleDeleteProduct (data) {
                this.selectedData = []
                this.selectedData.push(data)
                this.openDeletedModal = true
            },
            shopResultNumber (e) {
                this.limit = parseInt(e.target.value)
                this.getWebshopProducts(parseInt(e.target.value), this.priceListVal)
            },
            toggleEventAction (e) {
                this.showActionBox = true
            },
            proceedMassDelete (data) {
                this.selectedData = data
                this.openDeletedModal = true
                // window.Bus.$emit('remove-products', data)
            },
            removeEvent () {
                Bus.emit('removeActionBox')
                this.showActionBox = false
            },
            sortWebshopProductSKUAsc () {
                this.$store.commit('jitcontrol/SORT_WEBSHOP_PRODUCT_SKU_ASC')
            },
            sortWebshopProductSKUDesc () {
                this.$store.commit('jitcontrol/SORT_WEBSHOP_PRODUCT_SKU_DESC')
            },
            sortWebshopProductNameAsc () {
                this.$store.commit('jitcontrol/SORT_WEBSHOP_PRODUCT_NAME_ASC')
            },
            sortWebshopProductNameDesc () {
                this.$store.commit('jitcontrol/SORT_WEBSHOP_PRODUCT_NAME_DESC')
            },
            sortWebshopProductQTYAsc () {
                this.$store.commit('jitcontrol/SORT_WEBSHOP_PRODUCT_QTY_ASC')
            },
            sortWebshopProductQTYDesc () {
                this.$store.commit('jitcontrol/SORT_WEBSHOP_PRODUCT_QTY_DESC')
            },
            sortWebshopProductCostPriceAsc () {
                this.$store.commit('jitcontrol/SORT_WEBSHOP_PRODUCT_COSTPRICE_ASC')
            },
            sortWebshopProductCostPriceDesc () {
                this.$store.commit('jitcontrol/SORT_WEBSHOP_PRODUCT_COSTPRICE_DESC')
            },
            sortWebshopProductSalesAsc () {
                this.$store.commit('jitcontrol/SORT_WEBSHOP_PRODUCT_SALES_ASC')
            },
            sortWebshopProductSalesDesc () {
                this.$store.commit('jitcontrol/SORT_WEBSHOP_PRODUCT_SALES_DESC')
            },

        }
    }
</script>

<style lang="scss" scoped>
.custom-z {
    z-index: 9999;
}
</style>